<template>
  <div class="parametrs-container overflow-hidden">
    <div class="parametrs">
      <div class="header-parametrs">
        <span class="title">{{ $t('add_parametrs.add_parametrs') }}</span>
        <i
          class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
          @click="$emit('close')"
        ></i>
        <div class="header-protocol">
          <div
            v-if="addSuccessParametrs.length || addErrorParametrs.length"
            class="success-parametrs"
          >
            {{ $t('add_parametrs.no_accepted') }} :
            {{ addErrorParametrs.length }} / {{ $t('add_parametrs.accepted') }}:
            {{ addSuccessParametrs.length }}
          </div>
          <div v-else class="add-parametrs">
            <p>
              {{ $t('add_parametrs.protocol') }}: {{ unit.terminal_type.key }}
            </p>
            <span class="separate"> / </span>
            <p>
              {{ $t('add_parametrs.parametrs_available') }}:
              {{ parametrsLength }}
            </p>
          </div>
        </div>
        <skif-search
          class="search w-full"
          v-model="filterString"
          :placeholder="$t('search')"
          @searching="searching"
        />
      </div>
      <div
        v-if="!addSuccessParametrs.length && !addErrorParametrs.length"
        class="list"
      >
        <div
          v-for="item in filterParametrsChecked"
          :key="item.key"
          class="item"
        >
          <div :class="['item', { 'item--disabled': item.active }]">
            <skif-checkbox v-model="item.active" />
            {{ item.key }}
          </div>
        </div>
        <div
          v-for="item in filterParametrsNotChecked"
          :key="item.key"
          class="item"
        >
          <div :class="['item']">
            <skif-checkbox
              v-model="item.active"
              @change="changeChecked(item, $event)"
            />
            {{ item.key }}
          </div>
        </div>
      </div>
      <div v-if="addErrorParametrs.length" class="list-error">
        <div class="text">
          {{ $t('add_parametrs.support') }}
        </div>
        <div class="items">
          <div v-for="item in addErrorParametrs" :key="item.key" class="item">
            - {{ item.key }}
          </div>
        </div>
      </div>
      <div v-if="addSuccessParametrs.length" class="list-success">
        <div class="text">
          {{ $t('add_parametrs.parametrs_time') }}
        </div>
        <div class="items">
          <div
            v-for="(item, index) in addSuccessParametrs"
            :key="item.key + index"
            class="item"
          >
            - {{ item.key }}
          </div>
        </div>
      </div>
      <div class="footer-parametrs">
        <skif-button
          v-if="!addSuccessParametrs.length && !addErrorParametrs.length"
          class="send-btn-parametrs w-full"
          :disabled="listForSend.length === 0"
          @click.native="sendParametrs"
        >
          {{ $t('add_parametrs.send_parametrs') }} ({{ listForSend.length }})
        </skif-button>
        <skif-button
          v-else
          class="send-btn-parametrs w-full"
          @click.native="cancel"
        >
          {{ $t('add_parametrs.good') }}
        </skif-button>
      </div>
    </div>
  </div>
</template>

<script>
import { reportsApi } from '@/api'

export default {
  name: 'AddParametrs',
  props: {
    unit: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      filterString: '',
      parametrs: null,
      checkedList: [],
      notCheckedList: [],
      addSuccessParametrs: [],
      addErrorParametrs: [],
      listForSend: []
    }
  },
  computed: {
    parametrsLength() {
      return this.parametrs ? this.parametrs.length : +0
    },
    activeCount() {
      return this.parametrs
        ? this.parametrs.filter((prop) => prop.active).length
        : +0
    },
    filterParametrsChecked() {
      return this.checkedList
        ? this.checkedList.filter((item) =>
            item.key.includes(this.filterString)
          )
        : []
    },
    filterParametrsNotChecked() {
      return this.notCheckedList
        ? this.notCheckedList.filter((item) =>
            item.key.includes(this.filterString)
          )
        : []
    }
  },
  methods: {
    searching(val) {
      this.filterString = val
    },
    changeChecked(obj) {
      const index = this.listForSend.findIndex((item) => item.key === obj.key)
      if (index === -1) {
        this.listForSend.push({ key: obj.key })
      } else {
        this.listForSend.splice(index, 1)
      }
    },
    async sendParametrs() {
      const form = {
        terminal_type: this.unit.terminal_type.key,
        check_imei: this.unit.imei,
        columns: this.listForSend
      }
      await reportsApi
        .addParametrs(form)
        .then((resp) => {
          this.addSuccessParametrs = resp.data.add_columns
          this.$emit('get-sensors')
        })
        .catch((err) => {
          this.$notify.error({
            duration: 5000,
            title: `${this.$t('error')} \u{1f62a}`,
            message: err.response.data.message
          })
        })
    },
    cancel() {
      this.addErrorParametrs = []
      this.addErrorParametrs = []
      this.filterString = ''
      this.$emit('close')
    }
  },
  async mounted() {
    const { data } = await reportsApi.getParametrs(
      this.unit.terminal_type.key,
      this.unit.imei
    )
    this.parametrs = data.columns

    const activeItems = this.parametrs
      .filter((item) => item.active === true)
      .map((item) => {
        return { ...item, key: item.key }
      })

    this.checkedList = activeItems

    const notActiveItems = this.parametrs
      .filter((item) => item.active !== true)
      .map((item) => {
        return { ...item, key: item.key }
      })
    this.notCheckedList = notActiveItems
  }
}
</script>

<style lang="stylus" scoped>
.parametrs-container {
  background-color: #00000067;
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index: 2016;
}
.parametrs {
  position: absolute;
  top:50%;
  left:50%;
  transform : translate(-50%,-50%);
  color: #253545;
  width:400px;
  height:600px;
  background: #FFF;
  .header-parametrs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #446C9D;
    border-bottom: 1px solid #CAD6DF;
  }
  .header-protocol {
    font-weight: 600;
    font-size: 14px;
    color: #5A6C79;
    .add-parametrs {
      display: flex;
    }
    .separate {
      margin: 0 8px;
    }
  }
  .footer-parametrs {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    border-top: 1px solid #CAD6DF;
  }

  .search {
    border: none;
    margin-top: 16px;
    input {
      border-color: #e2e8f0
    }
  }
}

.list {
  height: 379px;
  overflow: scroll;
  margin-left: 25px;
  margin-top: 8px;
  .item {
    height: 36px;
    color: #446C9D;
    font-weight: 700;
    font-size: 16px;
    &--disabled {
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.list-error {
  margin: 20px 25px;
  padding: 10px;
  border-radius: 3px;
  background-color: #FFF1E9;
  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #DF7D47;
  }
  .items {
    .item {
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      color: #DF7D47;
      margin-top: 4px;
    }
  }
}

.list-success {
  margin: 20px 25px;
  padding: 10px;
  background: #DBEBFF;
  border-radius: 3px;
  .text {
    color: #20579A;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .items {
    .item {
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      color: #20579A;
      margin-top: 4px;
    }
  }
}


.send-button-parametrs {
  width: 100%;
  margin: 25px;
}
</style>
